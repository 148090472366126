import React from "react"

const Layout = ({ children }) => {
  return (
    <>
      <div className="w-full kakadu-bg">
        <div className="container mx-auto">{children}</div>
      </div>
    </>
  )
}

export default Layout
